import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import {   ISaveUserGroup, IUserGroup } from './user-group.model';
import { ICodeTitle } from 'ets-fe-ng-sdk';
export namespace UserGroupStore {
  //#region STORE
  export interface IState extends EntityState<IUserGroup> {}

  const adapter: EntityAdapter<IUserGroup> = createEntityAdapter<IUserGroup>({
    sortComparer: (a, b) => a.description.localeCompare(b.description),
    selectId: (i) => i.userGroup,
  });

  const initialState: IState = adapter.getInitialState();
  //#endregion

  //#region ACTIONS
  export const actions = {
    API: createActionGroup({
      source: 'User Group API',
      events: {
        create: props<{ item: ISaveUserGroup }>(),
        createError: props<{ error: any }>(),
        createSuccess: props<{ result: IUserGroup }>(),
        fetchAll: emptyProps(),
        fetchAllReset: emptyProps(),
        fetchAllError: props<{ error: any }>(),
        fetchAllSuccess: props<{ result: IUserGroup[] }>(),
        fetchSingle: props<{ userGroup: string }>(),
        fetchSingleError: props<{ error: any }>(),
        fetchSingleSuccess: props<{ result: IUserGroup }>(),
        update: props<{ item: ISaveUserGroup }>(),
        updateError: props<{ error: any }>(),
        updateSuccess: props<{ result: IUserGroup }>(),
      },
    }),
  };
  //#endregion

  //#region SELECTORE
  export namespace selectors {
    const select = (state: IAppState) => state.userGroup;
    export const selectAll = createSelector(select, adapter.getSelectors().selectAll);
    export const selectAllForInput = createSelector(
      selectAll,
      (list) => list.map<ICodeTitle>((x) => ({ code: x.userGroup, title: x.description })) || [],
    );
    export const selectAllMap = createSelector(select, adapter.getSelectors().selectEntities);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.API.fetchAllSuccess, (state, { result: items }) => {
      return adapter.setAll(items, state);
    }),
    // on(
    //   AuthActions.AuthAPI.loginSuccess,
    //   (state, { user, token }): AuthState.IState => ({ ...state, token, user }),
    // ),
    // on(AuthActions.logout, (state): AuthState.IState => ({ ...state, token: undefined, user: undefined })),
  );
  //#endregion
}
