import { Injectable, inject } from '@angular/core';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserGroupsService } from '../../../../../admin/src/app/dashboard/set-ups/user-groups/user-groups.service'; 
import { UserGroupStore } from './user-group.reducer';
import { BaseEffect } from '../base.effect';

@Injectable()
export class UserGroupEffect extends BaseEffect{
    protected service=inject (UserGroupsService);

  fetchAll = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGroupStore.actions.API.fetchAll),
      exhaustMap((action) =>
        this.service.fetchAll().pipe(
          map((r) => UserGroupStore.actions.API.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(UserGroupStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchSingle = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGroupStore.actions.API.fetchSingle),
      exhaustMap((action) =>
        this.service.fetchAll({ userGroup: action.userGroup }).pipe(
          map((r) => UserGroupStore.actions.API.fetchSingleSuccess({ result: r.content?.[0] })),
          catchError((error) => of(UserGroupStore.actions.API.fetchSingleError({ error }))),
        ),
      ),
    ),
  );

  create = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGroupStore.actions.API.create),
      exhaustMap((action) =>
        this.service.create(action.item).pipe(
          map((r) =>
            UserGroupStore.actions.API.createSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(UserGroupStore.actions.API.createError({ error }))),
        ),
      ),
    ),
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGroupStore.actions.API.update),
      exhaustMap((action) =>
        this.service.update(action.item).pipe(
          map((r) =>
            UserGroupStore.actions.API.updateSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(UserGroupStore.actions.API.updateError({ error }))),
        ),
      ),
    ),
  );

  createSuccess = this.actions$.pipe(
    ofType(UserGroupStore.actions.API.createSuccess),
    tap(() => this.store.dispatch(UserGroupStore.actions.API.fetchAllReset())),
  ); 
  updateSuccess = this.actions$.pipe(
    ofType(UserGroupStore.actions.API.updateSuccess),
    tap(() => this.store.dispatch(UserGroupStore.actions.API.fetchAllReset())),
  );
}
