import { Injectable } from '@angular/core';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import {
  ISaveUserGroup,
  IUserGroup,
} from '../../../../../../shared/src/lib/@ngrx/user-group/user-group.model';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root',
})
export class UserGroupsService extends UsersService<IUserGroup, ISaveUserGroup> {
  constructor() {
    super();
    this.baseURL = `v1/users/group/`;
  }

  override fetchAll(query?: { userGroup?: string }) {
    return this.get<ISR<IUserGroup>>(``, query);
  }

  override create(data: ISaveUserGroup) {
    return this.post<IUserGroup>(data);
  }
}
